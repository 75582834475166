import React from "react"

const SchedaLaboratorioPage = ({ className = "", title, children }) => (
  <div id={title} className={`mb-3 px-4 ${className}`}>
    <h5 className="mb-2 text-left">{title}</h5>
    
    <div className="pl-3">{children}</div>
  </div>
)

export default SchedaLaboratorioPage
