import React from "react"

import { Row, Col } from "react-bootstrap"

const ContentPage = ({ className = "" }) => (
  <div className={`p-5 ${className}`}>
    <Row className="no-gutters">
      <Col>
        <h3 className="text-left">Presentazione</h3>
        <hr />

        <p className="_02 paragraph">
          Archimede Research è uno spin-off, nato dal know-how acquisito da
          Archimede Srl in tanti anni di attività di Ricerca &amp; Sviluppo.Ad
          Oggi Archimede Research vanta partnerships con enti di ricerca quali
          Università di Catania, Università di Palermo, Consorzio di ricerca
          CERTA, Parco scientifico e Tecnologico della Sicilia ed Enea. Con i
          quali stiamo promuovendo diversi progetti di ricerca industriale.
          Archimede Research ha implementato un progetto per la trasformazione
          dell'energia solare in energia elettrica, termica o meccanica da un
          Concentratore Parabolico puntuale a montatura equatoriale (SEC). Il
          Concentratore è brevettato e depositato presso il Ministero dello
          Sviluppo Economico. Attualmente lo stesso brevetto è esteso ad alcuni
          paesi esterni all’Unione Europea. Inoltre, Archimede Research sta
          promuovendo diversi progetti di ricerca industriale nei seguenti
          ambiti: <br /> <br />- Produzione di metano arricchito con idrogeno
          (progetto “Solar Steam Reforming”), utilizzando come fonte primaria di
          energia la radiazione solare, progetto ancora in iter valutativo
          presso il Ministero dell’Istruzione dell’Università e della Ricerca –
          MIUR a valere del PON Ricerca e Sviluppo 2007/2013. I nostri Partner
          nel progetto sono l’Università degli Studi di Catania e Carbiolo
          s.p.a.; <br />
          -Produzione di Olio e Gas Pirolitico Solare dalle Biomasse (progetto
          “Solar Cracking”). Il progetto è ancora in iter valutativo presso il
          Ministero dell’Ambiente. Il nostro Partner nel progetto è l’Università
          degli Studi di Palermo; <br />
          -Dissalazione e/o depurazione di acque saline o di acque di processo
          con membrane pervaporative utilizzando, come fonte primaria di
          energia, la radiazione solare (progetto “Solar Desalination”), con
          costi di produzione competitivi con altre tecnologie. I partner, sono
          Archimede, Depurex 88 s.r.l., Leonardo – Sistemi Tecnologici Integrati
          s.r.l., Università di Palermo Dipartimento di Ingegneria Chimica e dei
          Processi, Consorzio per l’area sviluppo industriale di Palermo; <br />
          -Produzione di bio combustibili attraverso digestione anaerobica, e la
          decomposizione termocatalitica e successiva gassificazione di
          biomasse, contestuale produzione di fine chemicals da destinare
          all’industria della cosmesi (polifenoli, tannini, etc.) con estrazioni
          in CO2 supercritica. Il progetto “BioRaffineria” è autorizzato e
          finanziato dal Ministero dello Sviluppo economico nell’ambito del
          Bando Industria 2015. I nostri Partner sono il Parco Scientifico e
          Tecnologico della Sicilia – PST Sicilia e Busi Impianti
          s.p.a.;Produzione di biodiesel dalle microalghe vegetali coltivate in
          fotobioreattori tubolari (progetto “Algaenergy”). Il progetto è ancora
          in iter valutativo presso il Ministero dell’Ambiente. I nostri Partner
          nel progetto sono l’Università degli Studi di Palermo con referente il
          prof. Alonzo e il Parco Scientifico e Tecnologico della Sicilia – PST
          Sicilia ed ENEA; <br />
          -Produzione del Biochar dalla pirolisi del legno e dai rifiuti
          agricoli. Il suo uso come ammendante per mantenere la fertilità del
          suolo e fornire un serbatoio per l’accumulo di carbonio. Progetto
          presentato presso l’Unione Europea nell’ambito del bando ERA-NET
          Bioenergy. Il progetto “Biochar” è in iter valutativo. Il nostro
          Partner è l’Università degli Studi di Palermo. <br />
          -SUN PLUS: Sistema di Efficientamento Energetico di una centrale già
          esistente, prevede l’installazione di “riflettori” posti di fronte ai
          moduli fotovoltaici. Sfruttando i principi fisici della riflessione,
          la radiazione solare incidente sulla superficie riflettente, verrà
          proiettata sui moduli fotovoltaici. Questa, sovrapponendosi alla
          radiazione solare incidente, già presente, sui moduli, ne aumenterà il
          valore medio aumentando di conseguenza la produzione di energia
          elettrica. Il sistema è stato brevettato (Brevetto n. TO2014A000050
          del 23/01/14); <br />
          -Archimede Research è socio fondatore del “Distretto Tecnologico ed
          Energetico” in Sicilia presso l’Università degli Studi di Enna
          UNIKORE. <br /> <br />
          Archimede Srl, nel corso degli anni ha ampliato le proprie esperienze
          di ricerca e sviluppo nei diversi progetti di ricerca industriale con
          la collaborazione con università e centri di ricerca pubblici e
          privati e soprattutto attraverso le due società controllate Archimede
          Research Srl e il Parco Scientifico e Tecnologico della Sicilia
          (PSTS).
        </p>
      </Col>
    </Row>
  </div>
)

export default ContentPage
