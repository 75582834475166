import React from "react"

import { Row, Col } from "react-bootstrap"

const ContentPage = ({ className = "" }) => (
  <div className={`px-5 ${className}`}>
    <Row className="no-gutters">
      <Col>
        <h3 className="text-left">Brevetti</h3>
        <hr />
        <p className="_02 paragraph">
          -SUN PLUS: Sistema di Efficientamento Energetico di una centrale già
          esistente (Archimede Research Srl) (n. TO2014A000050 del 23/01/14)
          <br />
          -MOTERGBIO: Impianto di recupero e di smaltimento rifiuti con
          produzione di energia e biocombustibile (Archimede Srl.)
          (n.102015000011686 del 13/04/15).
          <br />
          -Processo di fermentazione per la produzione di poliidrossialcanoati e
          lo smaltimento di oli esausti mediante l'impiego di ceppi di
          Pseudomonas produttori di lipasi (PSTS)
          <br />
          -Lieviti per spumantizzazione (PSTS).
          <br />
          -Starter microbico per la panificazione (PSTS).
          <br />
          -Microbial starter for baking (PSTS).
          <br />
          -Metodo per la discriminazione di ceppi virali dal citrus tristeza
          virus (PSTS).
          <br />
        </p>
      </Col>
    </Row>
  </div>
)

export default ContentPage
