import React from "react"

import { Row, Col } from "react-bootstrap"

import Scheda from "./schedaLaboratorio"

const ContentPage = ({ className = "" }) => (
  <div className={`px-5 ${className}`}>
    <Row className="no-gutters">
      <Col>
        <h3 className="text-left">Laboratori</h3>
        <hr />

        <Scheda title="1. Laboratorio di Elettronica, Simulazioni e Calcolo Computazionale">
          - Cappa chimica di aspirazione mod. ECOAIR 2006 K180
          <br />
          - Pompa Quaternaria mod. VL1260 Infinity.
          <br />
          - Reattore Water gasification HPV 0,5L Batch
          <br />
        </Scheda>

        <Scheda title="2. Laboratorio di processi in acqua super critica">
          - Reattore di ossidazione in continuo della capacità di 0,5 litri;
          <br />
          - Pompa Lewa per alimentazione slurry;
          <br />
          - Compressore Olmeva per pressione di 300 bar;
          <br />
          - Analizzatore di combustione Decosta;
          <br />
          - Valvola di Back Pressure.
          <br />
        </Scheda>

        <Scheda title="3. Laboratori di genomica e diagnosi fitopatologica -Analizzatore genetico capillare a 4 capillari -Sistema real-time PCR (PSTS)">
          - In-Check platform St-Microelectronics
          <br />
        </Scheda>

        <Scheda title="4. Laboratorio di Tecnologie Alimentari (PSTS)">
          - Estrattore Speed SFE Applyed Separation in fase supercritica
          <br />
          - Fermentatore BIOSTAT 30 litri
          <br />
          - Sistema Olfattivo Artificiale o naso elettronico
          <br />
          - Camere di test di stabilità con illuminazione
          <br />
          - Confezionatrice vuoto gas
          <br />
        </Scheda>

        <Scheda title="5. Laboratorio Chimico (PSTS)">
          - Cromatografo ionico
          <br />
          - GC-MS dotato di rivelatore di massa con sistema di ionizzazione ad
          impatto elettronico (EI) e detector ECD e GC-MS
          <br />
          - Gascromatografo accoppiato a spettrometro di massa
          <br />
          - GC-MS completo di detector FID ed NPD -Spettrofotometro ad
          assorbimento atomico -Spettrofotometro UV-Vis
          <br />
          - Sistema HPLC-MS/M
          <br />
        </Scheda>
      </Col>
    </Row>
  </div>
)

export default ContentPage
