import React from "react"
import { Container } from "react-bootstrap"

import Content1 from "./content1"
import Content2 from "./content2"
import Content3 from "./content3"
import Content4 from "./content4"

const IndexContentPage = () => (
  <>
    <Container>
      <Content1 />
      <Content2 className="mt-1" />
      <Content3 className="mt-3" />
      <Content4 className="mt-3" />
    </Container>
  </>
)

export default IndexContentPage
