import React from "react"

import { Row, Col } from "react-bootstrap"

const ContentPage = ({ className = "" }) => (
  <div className={`px-5 ${className}`}>
    <Row className="no-gutters">
      <Col>
        <h3 className="text-left">Brevetti in corso</h3>
        <hr />

        <p className="_02 paragraph">
          -Vaccino naturale per il ceppo VT del virus della tristeza degli
          agrumi
          <br />
          -Produzione in pianta di polidrossialcanoati a media catena <br />
          -Molecole antimicrobiche difusibili e volatili da P. mediterranea
          <br />
        </p>
      </Col>
    </Row>
  </div>
)

export default ContentPage
